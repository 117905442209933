new Vue({
  el: '#podcast',
  data() {
    return {
      playback: 0,
      duration: 100,
      display: {
        playback: '0:00',
        duration: '0:00',
        percent: 'width: 0%',
      },
      listener: false,
      isLoaded: false,
      isPlaying: false,
      volume: 0.66,
    }
  },
  computed: {
    playIcon() {
      return this.isPlaying ? 'P' : 'S';
    }
  },
  methods: {
    initialise() {
      const player = this.$refs.player;
      if (player) {
        this.duration = Math.round(player.duration);
        this.display.duration = this.totalTime();
      }
    },
    convertTime(seconds){
      const format = val => `0${Math.floor(val)}`.slice(-2);
      const hours = seconds / 3600;
      const minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    totalTime() {
      const player = this.$refs.player;
      if (player) {
        const seconds = player.duration;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },
    elapsedTime() {
      const player = this.$refs.player;
      if (player) {
        const seconds = player.currentTime;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },
    percentPlayed() {
      const player = this.$refs.player;
      if (player) {
        const percent = (player.currentTime / player.duration) * 100;
        return `width: ${percent}%`;
      } else {
        return 'width: 0%';
      }
    },
    playbackListener(e) {
      const player = this.$refs.player;
      this.playback = player.currentTime;
      this.display.playback = this.elapsedTime();
      this.display.percent = this.percentPlayed();
      player.addEventListener('ended', this.endListener);
      player.addEventListener('pause', this.pauseListener);
    },
    pauseListener() {
      this.isPlaying = false;
      this.listener = false;
      this.cleanupListeners();
    },
    endListener() {
      this.isPlaying = false;
      this.listener = false;
      this.cleanupListeners();
    },
    cleanupListeners() {
      const player = this.$refs.player;
      player.removeEventListener("timeupdate", this.playbackListener);
      player.removeEventListener("ended", this.endListener);
      player.removeEventListener("pause", this.pauseListener);
    },
    toggleAudio() {
      const player = this.$refs.player;
      if (player.paused) {
        player.play();
        this.isPlaying = true;
      }else {
        player.pause();
        this.isPlaying = false;
      }
    },
    changeVolume() {
      const player = this.$refs.player;
      if (player) {
        if (this.volume + 0.33 <= 1){
          this.volume = this.volume + 0.33;
        }else {
          this.volume = 0;
        } 
        player.volume = this.volume;  
      }
    },
  },
  mounted() {
    this.$nextTick(function() {
      const player = this.$refs.player;
      if (!player) { return; }
      player.addEventListener('loadedmetadata', (e) => {this.initialise();});
      player.addEventListener('canplay', (e) => {this.isLoaded = true;});

      this.$watch('isPlaying', () => {
        if (this.isPlaying) {
          const player = this.$refs.player;
          this.initialise();
        }

        if (!this.listener) {
          this.listener = true;
          player.addEventListener('timeupdate', this.playbackListener);
        }
      });
      this.$watch("playback",function() {
        const player = this.$refs.player;
        const diff = Math.abs(this.playback - this.$refs.player.currentTime);    
        if(diff>0.01) {
          this.$refs.player.currentTime = this.playback;
        }
      });
    });
  }
});